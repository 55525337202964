
/**
 * @name: 商品销量统计
 * @author: itmobai
 * @date: 2023-07-24 11:40
 * @description：商品销量统计
 * @update: 2023-07-24 11:40
 */
import {Component, Vue} from "vue-property-decorator";
import { mallSaleOrderDtlSalesStatisticsByPageApi, mallSaleOrderDtlSalesStatisticsExportApi } from "@/apis/data/sale"
import { ISaleParam } from "@/apis/data/sale/types";
import { ICrudOption } from "@/types/m-ui-crud";
import { deepCopy, exportFile } from "@/utils/common";
import { mallSiteQueryByListApi } from "@/apis/site/list"

@Component({})
export default class SalePage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: any[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: ISaleParam = {
    page: 1,
    limit: 10
  }
  // 销售数量合计
  saleSum = 0
  // 商品金额合计
  productPriceSum = 0
  // 支付金额合计
  payPriceSum = 0
  // 表单数据
  modelForm: Partial<any> = {}
  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "商品",
        prop: "goodsKeywords",
        search: true,
        placeholder: "输入商品名称/ID模糊搜索",
        hide: true
      },
      {
        label: "商品ID",
        prop: "goodsId",
        align: "left",
        width: 180
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center"
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        search: true,
        type: "select",
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          }
        ]
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        search: true,
        placeholder: "请选择商品分类",
        type: "select",
        dicUrl: '/api/admin/productSort/dropDown',
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
      },
      {
        label: "商品分类",
        prop: 'sortName',
        align: "center",
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
      },
      {
        label: "站点",
        prop: "siteId",
        search: true,
        type: "select",
        dicData: [],
        hide: true,
      },
      {
        label: "销售数量(份)",
        prop: "qty",
        align: "center",
        width: 100,
      },
      {
        label: "销售单价(元/份)",
        prop: "price",
        align: "center",
        width: 120,
      },
      {
        label: "销售金额(元)",
        prop: "goodsPrice",
        align: "center",
        width: 120,
      },
      {
        label: "客户实付(元)",
        prop: "goodsPayPrice",
        align: "center",
        width: 120,
      },
      {
        label: "关联订单",
        prop: "saleOrderSn",
        align: "center",
        search: true,
        width: 180,
      },
      {
        label: "销售时间",
        prop: "orderTime",
        search: true,
        type: "daterange",
        align: "center",
        width: 180
      }
    ]
  }

  getList () {
    this.tableLoading = true
    const form: ISaleParam = deepCopy(this.queryForm);
    if (this.queryForm.orderTime && this.queryForm.orderTime.length) {
      form.orderTimeStart = this.queryForm.orderTime[0]
      form.orderTimeEnd = this.queryForm.orderTime[1]
    } else {
      form.orderTimeStart = ""
      form.orderTimeEnd = ""
    }
    delete form.orderTime
    mallSaleOrderDtlSalesStatisticsByPageApi(form).then(e => {
      this.saleSum = e.qtyAmount;
      this.productPriceSum = e.goodsPriceAmount;
      this.payPriceSum = e.goodsPayPriceAmount
      this.tableTotal = e.saleOrderDtlVoPage.total
      this.tableData = e.saleOrderDtlVoPage.records
      this.tableLoading = false
    })
  }

  getSiteSelect () {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const form: ISaleParam = deepCopy(this.queryForm);
    if (this.queryForm.orderTime && this.queryForm.orderTime.length) {
      form.orderTimeStart = this.queryForm.orderTime[0]
      form.orderTimeEnd = this.queryForm.orderTime[1]
    } else {
      form.orderTimeStart = ""
      form.orderTimeEnd = ""
    }
    delete form.orderTime
    mallSaleOrderDtlSalesStatisticsExportApi(form).then(e => {
      exportFile(e, '商品销量统计.xlsx')
    })
  }

  created () {
    this.getList()
    this.getSiteSelect()
  }
}
