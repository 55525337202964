/**
 * @name: 商品销量统计接口
 * @author: itmobai
 * @date: 2023-07-25 11:24
 * @description：商品销量统计接口
 * @update: 2023-07-25 11:24
 */
import { get } from "@/request";
import { ISaleParam } from "./types";

export const mallSaleOrderDtlSalesStatisticsByPageApi = (query: ISaleParam) => get("/mall/saleOrderDtl/salesStatisticsByPage", query)

export const mallSaleOrderDtlSalesStatisticsExportApi = (query: ISaleParam) => get("/mall/saleOrderDtl/salesStatisticsExport", query, "blob")
